import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface cookiesState {
    userAgreedToCookies: boolean;
    userAgreedToAnalyticsCookie: boolean;
    userAgreedToPreferencesCookie: boolean;
};

const initialState: cookiesState = {
    userAgreedToCookies: false,
    userAgreedToAnalyticsCookie: false,
    userAgreedToPreferencesCookie: false
};

export const cookiesSlice = createSlice({
    name: 'cookies',
    initialState: initialState,
    reducers: {
        setUserAgreedToCookies: (state, action: PayloadAction<boolean>) => {
            state.userAgreedToCookies = action.payload;
        },
        setUserAgreedToAnalyticsCookie: (state, action: PayloadAction<boolean>) => {
            state.userAgreedToAnalyticsCookie = action.payload;
        },
        setUserAgreedToPreferencesCookie: (state, action: PayloadAction<boolean>) => {
            state.userAgreedToPreferencesCookie = action.payload;
        }
    }
});

export const {
    setUserAgreedToCookies,
    setUserAgreedToAnalyticsCookie,
    setUserAgreedToPreferencesCookie
} = cookiesSlice.actions;

export default cookiesSlice.reducer;