import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { RootState } from "./store";

export const usePageTracking = () => {
    const TRACKING_ID = "G-Q0G6WDC99M";

    const usingAnalytics = useSelector((state: RootState) => state.cookies.userAgreedToAnalyticsCookie);
    const usingCookies = useSelector((state: RootState) => state.cookies.userAgreedToCookies);

    const location = useLocation();

    const canUseTracking = () => {
        return usingAnalytics === true || usingCookies === false;
    };

    const sendPageChangeByUser = () => {
        const pagePath = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: pagePath});
    };

    useEffect(()=>{
        if (canUseTracking()){
            ReactGA.initialize(TRACKING_ID);
        }
    },[usingAnalytics, usingCookies]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (canUseTracking()){
            sendPageChangeByUser();
        }
    },[location, usingAnalytics, usingCookies]); // eslint-disable-line react-hooks/exhaustive-deps
};