import { useEffect } from "react";

import { loadTranslatableTitles, TranslatedText } from "../../functions/translate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShapes, faRocket, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const HomePage = () => {

    useEffect(()=>{
        loadTranslatableTitles(
            "Solutions logicielles adaptées à vos besoins",
            "Software solutions tailored to your needs");
    },[]);

    return (
        <div className="page">
            <div className="home-page">
                <div className="home-page-top-banner">

                </div>
                <div className="home-page-values-section">
                    <TranslatedText en="Our apps are ..." fr="Nos applications sont ..."/>
                    <div>
                        <ValueElement
                            icon={faShapes}
                            titleEn="Simple"
                            titleFr="Simples"
                            textEn="Every service we offer is designed to be as simple to use as possible for you and anyone you work with."
                            textFr="Tous les services que nous offrons sont conçus pour être aussi simple à utiliser que possible pour vous et toutes les personnes avec qui vous travaillez."/>
                        <ValueElement
                            icon={faRocket}
                            titleEn="Fast"
                            titleFr="Rapides"
                            textEn="Our entire infrastructure is built around speed. We know the value of time and the importance of saving every bit of yours."
                            textFr="L'intégralité de notre infrastructure a été bâtie pour la vitesse. Nous connaissons la valeur du temps et l'importance d'économiser chaque seconde du vôtre."/>
                        <ValueElement
                            icon={faShieldHalved}
                            titleEn="Secure"
                            titleFr="Sécurisées"
                            textEn="Every service we offer is up to the latest security standards. We make sure your data is safe and secure, forever."
                            textFr="Tous nos services offerts sont conformes aux normes de sécurité les plus récentes. Nous veillons à ce que vos données restent en lieu sûr."/>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ValueElementProps {
    icon: IconProp;
    titleEn: string;
    titleFr: string;
    textEn: string;
    textFr: string;
};

const ValueElement = ({icon, titleEn, titleFr, textEn, textFr}: ValueElementProps) => {
    return (
        <div className="home-page-value-element">
            <i><FontAwesomeIcon icon={icon}/></i>
            <TranslatedText en={titleEn} fr={titleFr}/>
            <TranslatedText en={textEn} fr={textFr}/>
        </div>
    );
}

export default HomePage;