import { configureStore } from "@reduxjs/toolkit";

import settingsSlice from './reducers/settings';
import titleSlice from './reducers/title';
import cookiesSlice from './reducers/cookies';

export const store = configureStore({
    reducer: {
        settings: settingsSlice,
        title: titleSlice,
        cookies: cookiesSlice
    }
});

export type RootState = ReturnType<typeof store.getState>;