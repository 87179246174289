import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState, store } from "./store";
import { setTitles } from './store/reducers/title';

interface TranslatedTextProps {
    fr: string;
    en: string;
};

const TranslatedText = ({fr, en}: TranslatedTextProps) => {
    const english: boolean = useSelector((state: RootState) => state.settings.english);
    return (<p>{english ? en : fr}</p>);
};

const TranslatedTitle = () => {
    const english = useSelector((state: RootState) => state.settings.english);
    const titles = useSelector((state: RootState) => state.title);
    useEffect(()=>{
        document.title = `Infrasolva${titles.en !== "" && titles.fr !== "" ? ' - ' : ''}${english ? titles.en : titles.fr}`;
    },[english, titles]);

    return (<></>);
}

interface translatedTextOnlyData {
    fr: string;
    en: string;
}

const translatedTextOnly = ({fr, en}: translatedTextOnlyData) =>{
    const english = store.getState().settings.english;
    return english ? en : fr;
}

const loadTranslatableTitles = (fr: string, en: string) => {
    store.dispatch(setTitles({fr: fr, en: en}));
};

export { TranslatedText, TranslatedTitle, loadTranslatableTitles, translatedTextOnly };