import { NavigateFunction } from "react-router-dom";
import { store } from "./store";
import { setBurgerActive, setProductMenuClosed } from "./store/reducers/settings";

let navigateFn: NavigateFunction;

export const setNavigate = (navigate: NavigateFunction) => {
    navigateFn = navigate;
};

export const goToPage = (uri: string) => {
    const actualUri = uri === "/" ? "" : uri;
    store.dispatch(setBurgerActive(false));
    store.dispatch(setProductMenuClosed(true));
    navigateFn(`/${actualUri}`, { replace: true });
};