import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faXTwitter, faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { TranslatedText } from "../../functions/translate";
import { goToPage } from "../../functions/gotopage";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface footerProps {
    available: boolean;
};

const Footer = ({available}: footerProps) => {
    return(available ?
        <div className="footer">
            <div>
                <div className="footer-logo-n-socials">
                    <div className="footer-logo">
                        <div></div>
                        <p>INFRASOLVA</p>
                    </div>
                    <div className="footer-socials">
                        <FooterSocialLink icon={faLinkedin} uri="https://linkedin.com"/>
                        <FooterSocialLink icon={faXTwitter} uri="https://x.com"/>
                        <FooterSocialLink icon={faFacebook} uri="https://facebook.com"/>
                        <FooterSocialLink icon={faInstagram} uri="https://instagram.com"/>
                        <FooterSocialLink icon={faYoutube} uri="https://youtube.com"/>
                    </div>
                </div>
                <div className="footer-sitemap">
                    <FooterTitleAndList
                        titleEn="Sitemap"
                        titleFr="Plan du site"
                        list={[
                            {
                                uri: "/",
                                en: "Home",
                                fr: "Accueil"
                            },
                            {
                                uri: "company",
                                en: "Company",
                                fr: "Entreprise"
                            },
                            {
                                uri: "news",
                                en: "News",
                                fr: "Nouvelles"
                            },
                            {
                                uri: "contact",
                                en: "Contact",
                                fr: "Contact"
                            }
                        ]}/>
                    <FooterTitleAndList
                        titleEn="Products"
                        titleFr="Produits"
                        list={[
                            {
                                uri: "product/eagle-work",
                                en: "Eagle work",
                                fr: "Eagle work"
                            }
                        ]}/>
                </div>
            </div>
            <div className="footer-separator"></div>
            <div>
                <p>© {new Date().getFullYear()} Infrasolva</p>
                <FooterLink uri="/" textEn="Terms of use" textFr="Conditions d'utilisation"/>
                <FooterLink uri="/" textEn="Privacy" textFr="Confidentialité"/>
                <FooterLink uri="/" textEn="Cookies" textFr="Cookies"/>
            </div>
        </div>
    : <></>);
};

interface footerTitleListData{
    uri: string;
    en: string;
    fr: string;
};

interface footerTitleListProps {
    titleEn: string;
    titleFr: string;
    list: Array<footerTitleListData>
};

const FooterTitleAndList = ({titleEn, titleFr, list}: footerTitleListProps) => {
    return (
        <div className="footer-title-list">
            <TranslatedText en={titleEn} fr={titleFr}/>
            <div>
                {list.map((item, index: number) => {
                    return (
                        <Fragment key={index}>
                            <FooterLink
                                uri={item.uri}
                                textEn={item.en}
                                textFr={item.fr}/>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

interface footerLinkProps {
    uri: string;
    textEn: string;
    textFr: string;
};

const FooterLink = ({uri, textEn, textFr}: footerLinkProps) => {
    return (
        <div className="footer-link" onClick={()=>{goToPage(uri)}}>
            <TranslatedText en={textEn} fr={textFr}/>
        </div>
    );
};

interface footerSocialLinkProps {
    icon: IconProp;
    uri: string;
};

const FooterSocialLink = ({icon, uri}: footerSocialLinkProps) => {
    return (
        <a href={uri} target="_blank" rel="noreferrer">
            <i><FontAwesomeIcon icon={icon}/></i>
        </a>
    );
};

export default Footer;