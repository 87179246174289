const ENV = process.env.NODE_ENV || "dev";
const NAMESPACE = ENV === "production" ? "1NfR4z0!vA" : `1NfR4z0!vA_${ENV}_`;

const save = (key: string, value: any) => {
    localStorage.setItem(NAMESPACE + key, JSON.stringify(value));
};

const read = (key: string, defaultValue: any = null) => {
    try{
        const value = localStorage.getItem(NAMESPACE + key);
        return value === null ? defaultValue : JSON.parse(value);
    }
    catch{
        return defaultValue;
    }
};

const remove = (key: string) => {
    localStorage.removeItem(NAMESPACE + key);
};

export { save, read, remove };