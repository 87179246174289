import { useState, useEffect } from "react";

import { store } from "../../functions/store";
import { setLanguage } from "../../functions/store/reducers/settings";
import { didUserAgreedToPreferencesCookie, defaultLanguage, setDefaultLanguage } from "../../functions/cookies";

const LanguageMenu = () => {

    const [english, setEnglish] = useState(false);
    const [englishFirst, setEnglishFirst] = useState(false);
    const [open, setOpen] = useState(false);
    const [northAmerican, setNorthAmerican] = useState(true);

    const canUseCookies = didUserAgreedToPreferencesCookie();

    useEffect(()=>{
        const languageInMemory = defaultLanguage();
        const language = languageInMemory !== null ? languageInMemory :
        navigator.language.substring(0, 2);
        setNorthAmerican(navigator.language.toLocaleLowerCase().includes("ca") ||
                         navigator.language.toLocaleLowerCase().includes("us") ||
                         navigator.language.toLocaleLowerCase().includes("mx") ||
                         Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase().includes("america"));
        store.dispatch(setLanguage(language !== 'fr'));
        setEnglishFirst(language !== 'fr');
        setEnglish(language !== 'fr');
        if (canUseCookies){
            setDefaultLanguage(language);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        const htmlTag = document.documentElement;
        htmlTag.lang = "x";
        setTimeout(() => {
            htmlTag.lang = english ? "en" : "fr";
        }, 0);
    },[english]);

    const setCurrentLanguage = (en: boolean) =>{
        store.dispatch(setLanguage(en));
        setEnglish(en);
        if (canUseCookies){
            setDefaultLanguage(en ? 'en' : 'fr');
        }
        setOpen(false);
    }

    return (
        <div className="language-menu">
            <div onMouseOver={()=>{setOpen(true)}}>
                <div className={`languages ${
                    english ?
                        northAmerican ? 'english' : 'greatbritain' :
                        northAmerican ? 'quebecois' : 'french'}`}>
                </div>
            </div>
            <div>
                <div style={!open ? {maxHeight: '0px', padding: '0 20px'} : {}}>
                    <LanguageElement fr={!englishFirst} northAmerican={northAmerican} setLanguage={setCurrentLanguage}/>
                    <LanguageElement fr={englishFirst} northAmerican={northAmerican} setLanguage={setCurrentLanguage}/>
                </div>
            </div>
        </div>
    );
};

interface LanguageElementProps {
    fr: boolean;
    northAmerican: boolean;
    setLanguage: (en: boolean) => void;
};

const LanguageElement = ({fr, northAmerican, setLanguage}: LanguageElementProps) => {
    return (
        <div className="language-element" onClick={()=>{setLanguage(!fr)}}>
            <div className={`languages ${
                fr ?
                    northAmerican ? 'quebecois' : 'french' :
                    northAmerican ? 'english' : 'greatbritain'}`}>
            </div>
            <p>{fr ? 'FR' : 'EN'}</p>
        </div>
    );
};

export default LanguageMenu;