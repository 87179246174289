import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface settingsState {
    english: boolean;
    burgerActive: boolean;
    productMenuClosed: boolean;
};

const initialState: settingsState = {
    english: true,
    burgerActive: false,
    productMenuClosed: true
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<boolean>) => {
            state.english = action.payload;
        },
        setBurgerActive: (state, action: PayloadAction<boolean>) => {
            state.burgerActive = action.payload;
        },
        setProductMenuClosed: (state, action: PayloadAction<boolean>) => {
            state.productMenuClosed = action.payload;
        }
    }
});

export const {
    setLanguage,
    setBurgerActive,
    setProductMenuClosed
} = settingsSlice.actions;

export default settingsSlice.reducer;